import React from "react";
import "./App.css";

function Footer() {
	return (
		<footer className='App-footer'>
			<h2>No distractions video App</h2>
		</footer>
	);
}

export default Footer;
