import React from "react";
import Heading from "./Heading";
import Footer from "./Footer";
import Cards from "./Cards";
import "./App.css";

function App() {
	return (
		<div className='App'>
			<Heading />
			<Cards />
			<Footer />
		</div>
	);
}

export default App;
