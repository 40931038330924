import React from "react";
import "./App.css";

function Heading() {
	return (
		<header className='App-header'>
			<h1>MyTube</h1>
		</header>
	);
}

export default Heading;
